import '../../pages/Home/index.css';
// Deuxieme projet
import yunoTexture from 'assets/yuno.png';
import yunoTexture1 from 'assets/yuno1.png';

// Spotify
import { default as spotifyCompanion, default as spotifyCompanionLarge } from 'assets/spotifycompanion.JPG';

// Twitter
import { default as twitterClone, default as twitterCloneLarge } from 'assets/twitterclone.JPG';


import { default as sliceTexture, default as sliceTextureLarge, default as sliceTexturePlaceholder } from 'assets/ml.png';
import { default as symphone, default as symphoneLarge, default as symphoneHolder } from 'assets/symphone_app.jpeg';
import { default as symphone1, default as symphone1Large, default as symphone1Holder } from 'assets/symphone_app2.jpeg';
import ProjectSummary from 'pages/Home/ProjectSummary';
import { useEffect, useRef, useState } from 'react';

const AllProjects = (props) => {
    const [visibleSections, setVisibleSections] = useState([]);
    const { scrollIndicatorHidden, setScrollIndicatorHidden } = props;
    const projectOne = useRef();
    const projectTwo = useRef();
    const projectThree = useRef();
    const projectFour = useRef();

    useEffect(() => {
        const revealSections = [projectOne, projectTwo, projectThree, projectFour];

        const sectionObserver = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const section = entry.target;
                        observer.unobserve(section);
                        if (visibleSections.includes(section)) return;
                        setVisibleSections(prevSections => [...prevSections, section]);
                    }
                });
            },
            { rootMargin: '0px 0px -10% 0px' }
        );

        const indicatorObserver = new IntersectionObserver(
            ([entry]) => {
                setScrollIndicatorHidden(!entry.isIntersecting);
            },
            { rootMargin: '-100% 0px 0px 0px' }
        );

        revealSections.forEach(section => {
            sectionObserver.observe(section.current);
        });

        indicatorObserver.observe(projectOne.current);

        return () => {
            sectionObserver.disconnect();
            indicatorObserver.disconnect();
        };
    }, [visibleSections]);


    return (
        <div>
            <ProjectSummary
                id="project-1"
                sectionRef={projectOne}
                visible={visibleSections.includes(projectOne.current)}
                index={1}
                store={true}
                title="CTO de Symphone"
                description="Création d'une start-up pour apprendre aux débutants le piano plus simplement grâce à une barre connectée et des lumières. Mise en place d'une application mobile et de son site vitrine."
                buttonText="Voir sur App store"
                buttonLink="https://apps.apple.com/fr/app/symphone/id6471275101"
                icon="apple"
                button1Text="Voir sur Google Play"
                button1Link="https://play.google.com/store/apps/details?id=com.emergencei.symphone&hl=fr"
                icon1="google"
                model={{
                    type: 'phone',
                    alt: 'Symphone',
                    textures: [
                        {
                            src: symphone1,
                            srcSet: `${symphone1} 254w, ${symphone1} 508w`,
                            placeholder: symphone1,
                        },
                        {
                            src: symphone,
                            srcSet: `${symphone} 254w, ${symphone} 508w`,
                            placeholder: symphone,
                        },
                    ],
                }}
            />
            <ProjectSummary
                id="project-2"
                alternate
                sectionRef={projectTwo}
                visible={visibleSections.includes(projectTwo.current)}
                index={2}
                store={true}
                title="Pick : Votez pour la meilleure photo"
                description="Développeur de Pick, un comparateur de photo"
                buttonText="Voir sur App store"
                buttonLink="https://apps.apple.com/us/app/pick/id6450153921"
                icon="apple"
                button1Text="Voir sur Google Play"
                button1Link="https://play.google.com/store/apps/details?id=com.firefly.pick"
                icon1="google"
                model={{
                    type: 'phone',
                    alt: 'Pick',
                    textures: [
                        {
                            src: yunoTexture,
                            srcSet: `${yunoTexture} 254w, ${yunoTexture} 508w`,
                            placeholder: yunoTexture,
                        },
                        {
                            src: yunoTexture1,
                            srcSet: `${yunoTexture1} 254w, ${yunoTexture1} 508w`,
                            placeholder: yunoTexture1,
                        },
                    ],
                }}
            />

            <ProjectSummary
                id="project-3"
                sectionRef={projectThree}
                visible={visibleSections.includes(projectThree.current)}
                index={3}
                store={true}
                title="Spotify Profiler"
                description="Retrouvez toutes les paroles de vos musiques favorites et obtenez les statistiques des musiques grâce à ce site créé en React et NodeJs. Créé pour les cours de React à Efrei Paris. (Fait avant que Spotify ajoute les paroles)"
                buttonText="Voir le projet"
                buttonLink="https://spotify-dash.herokuapp.com/"
                icon="spotify"
                button1Text="Voir le projet sur Github"
                button1Link="https://github.com/SebastienGt/spotify-companion"
                icon1="github"
                model={{
                    type: 'laptop',
                    alt: 'Smart Sparrow lesson builder',
                    textures: [
                        {
                            src: spotifyCompanion,
                            srcSet: `${spotifyCompanion} 800w, ${spotifyCompanionLarge} 1440w`,
                            placeholder: spotifyCompanion,
                        },
                    ],
                }}
            />
            <ProjectSummary
                id="project-4"
                sectionRef={projectFour}
                visible={visibleSections.includes(projectFour.current)}
                index={4}
                store={true}
                icon={"github"}
                title="AI : Deep Learning"
                description="Mise en place d'une intelligence artificielle. Développement de comportements complexes, fait pour les cours de Machine Learning avec l'université polytechnique de catalogne et l'université de Barcelone lors de mon semestre à l'étranger."
                buttonText="Voir le projet sur Github"
                buttonLink="https://github.com/blobquiet/Hider-and-Seeker-with-Reinforcement-Learning-Unity-ML-Agents-"
                button1Text="Voir la thèse de recherche"
                button1Link="https://drive.google.com/file/d/12wwRcMHF5alRkOfA5pQJiq3aoQJM1pZX/view"
                icon1=""
                model={{
                    type: 'laptop',
                    alt: '',
                    textures: [
                        {
                            src: sliceTexture,
                            srcSet: `${sliceTexture} 980w, ${sliceTextureLarge} 1376w`,
                            placeholder: sliceTexturePlaceholder,
                        },
                    ],
                }}
            />
        </div >
    );
};

export default AllProjects;
