import classNames from 'classnames';
import { Button } from 'components/Button';
import Divider from 'components/Divider';
import Heading from 'components/Heading';
import Model from 'components/Model';
import deviceModels from 'components/Model/deviceModels';
import Section from 'components/Section';
import Text from 'components/Text';
import { useTheme } from 'components/ThemeProvider';
import { useWindowSize } from 'hooks';
import { Fragment } from 'react';
import { Button as But } from 'react-bootstrap';
import { Transition } from 'react-transition-group';
import { media } from 'utils/style';
import { isVisible, reflow } from 'utils/transition';
import './ProjectSummary.css';

const ProjectSummary = ({
  id,
  visible,
  store,
  sectionRef,
  index,
  title,
  description,
  model,
  buttonText,
  button1Text,
  buttonLink,
  button1Link,
  icone,
  alternate,
  confidential,
  icon,
  icon1,
  ...rest
}) => {
  const theme = useTheme();
  const { width } = useWindowSize();
  const titleId = `${id}-title`;
  const isMobile = width <= media.tablet;
  const svgOpacity = theme.themeId === 'light' ? 0.7 : 1;
  const indexText = index < 10 ? `0${index}` : index;
  const phoneSizes = `(max-width: ${media.tablet}px) 30vw, 20vw`;
  const laptopSizes = `(max-width: ${media.tablet}px) 80vw, 40vw`;

  const renderDetails = status => (
    <div className="project-summary__details">
      <div aria-hidden className="project-summary__index">
        <Divider
          notchWidth="64px"
          notchHeight="8px"
          collapsed={status !== 'entered'}
          collapseDelay={1000}
        />
        <span
          className={classNames(
            'project-summary__index-number',
            `project-summary__index-number--${status}`
          )}
        >
          {indexText}
        </span>
      </div>
      <Heading
        level={3}
        as="h2"
        className={classNames(
          'project-summary__title',
          `project-summary__title--${status}`
        )}
        id={titleId}
      >
        {title}
      </Heading>
      <Text
        className={classNames(
          'project-summary__description',
          `project-summary__description--${status}`
        )}
      >
        {description}
      </Text>
      <div
        className={classNames(
          'project-summary__button',
          `project-summary__button--${status}`
        )}
      >
        {
          store ? (<>
            <Button iconHoverShift href={buttonLink} icon={icon} style={{ marginBottom: 5 }}>
              {buttonText}
            </Button>
            <Button iconHoverShift href={button1Link} icon={icon1} >
              {button1Text}
            </Button>
          </>) :
            (
              confidential ? (
                <But disabled={true} href={""} icon={icone}> {buttonText}</But>
              ) :
                (
                  <Button iconHoverShift href={buttonLink} icon={icone} iconEnd="arrowRight">{buttonText}</Button>
                ))
        }
      </div>
    </div >
  );
  //  <Button iconHoverShift href={buttonLink} iconEnd="arrowRight">
  const renderPreview = status => (
    <div className="project-summary__preview">
      {model.type === 'laptop' && (
        <Fragment>
          <Model
            className={classNames(
              'project-summary__model',
              'project-summary__model--laptop'
            )}
            alt={model.alt}
            cameraPosition={{ x: 0, y: 0, z: 8 }}
            showDelay={700}
            show={isVisible(status)}
            models={[
              {
                ...deviceModels.laptop,
                texture: {
                  ...model.textures[0],
                  sizes: laptopSizes,
                },
              },
            ]}
          />
        </Fragment>
      )}
      {model.type === 'phone' && (
        <Fragment>
          <Model
            className={classNames(
              'project-summary__model',
              'project-summary__model--phone'
            )}
            alt={model.alt}
            cameraPosition={{ x: 0, y: 0, z: 11.5 }}
            showDelay={300}
            show={isVisible(status)}
            models={[
              {
                ...deviceModels.phone,
                position: { x: -0.6, y: 1.1, z: 0 },
                texture: {
                  ...model.textures[0],
                  sizes: phoneSizes,
                },
              },
              {
                ...deviceModels.phone,
                position: { x: 0.6, y: -0.5, z: 0.3 },
                texture: {
                  ...model.textures[1],
                  sizes: phoneSizes,
                },
              },
            ]}
          />
        </Fragment>
      )}
    </div>
  );

  return (
    <Section
      className={classNames('project-summary', {
        'project-summary--alternate': alternate,
        'project-summary--first': index === '01',
      })}
      as="section"
      aria-labelledby={titleId}
      ref={sectionRef}
      id={id}
      tabIndex={-1}
      {...rest}
    >

      <div className="project-summary__content">
        <Transition in={visible} timeout={0} onEnter={reflow}>
          {status => (
            <Fragment>
              {!alternate && !isMobile && (
                <Fragment>
                  {renderDetails(status)}
                  {renderPreview(status)}
                </Fragment>
              )}
              {(alternate || isMobile) && (
                <Fragment>
                  {renderPreview(status)}
                  {renderDetails(status)}
                </Fragment>
              )}
            </Fragment>
          )}
        </Transition>
      </div>
    </Section>
  );
};

export default ProjectSummary;
