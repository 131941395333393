import './index.css';
import iphone11 from 'assets/iphone-11.glb';
import macbookPro from 'assets/macbook-pro.glb';
import Footer from 'components/Footer';
import ListFormations from 'components/Formations/ListFormations';
import { ProjectSectionHeading, ProjectTextRow } from 'components/ProjectLayout';
import AllProjects from 'components/projects/AllProjectsComponent';
import Section from "components/Section";
import { usePrefersReducedMotion, useRouteTransition } from 'hooks';
import Experience from 'pages/Experience/Experience';
import { basic_info, experiences } from 'pages/Experience/info';
import Intro from 'pages/Home/Intro';
import Profile from 'pages/Home/Profile';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import Image from 'components/Image';
import ProfileImg from 'assets/Paris.png';


//const AllProjects = lazy(() => import('components/projects/AllProjectsComponent'));

const disciplines = [
  'Full-stack',
  'UX/UI',
  'VR & AR',
  'AI',
  'Ruby',
  'React',
  'React-Native',
  'Cloud',
  'Node.Js',

];

const Home = () => {
  const { status } = useRouteTransition();
  const { hash, state } = useLocation();
  const initHash = useRef(true);
  const [visibleSections, setVisibleSections] = useState([]);
  const [scrollIndicatorHidden, setScrollIndicatorHidden] = useState(false);
  const intro = useRef();
  const details = useRef();
  const formation = useRef();
  const experience = useRef();
  const allProjects = useRef();
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    const revealSections = [intro, details, formation];

    const sectionObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const section = entry.target;
            observer.unobserve(section);
            if (visibleSections.includes(section)) return;
            setVisibleSections(prevSections => [...prevSections, section]);
          }
        });
      },
      { rootMargin: '0px 0px -10% 0px' }
    );

    const indicatorObserver = new IntersectionObserver(
      ([entry]) => {
        setScrollIndicatorHidden(!entry.isIntersecting);
      },
      { rootMargin: '-100% 0px 0px 0px' }
    );

    revealSections.forEach(section => {
      sectionObserver.observe(section.current);
    });

    indicatorObserver.observe(intro.current);

    return () => {
      sectionObserver.disconnect();
      indicatorObserver.disconnect();
    };
  }, [visibleSections]);

  useEffect(() => {
    const hasEntered = status === 'entered';
    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
    let scrollObserver;
    let scrollTimeout;

    const handleHashchange = (hash, scroll) => {
      clearTimeout(scrollTimeout);
      const hashSections = [intro, formation, allProjects, details];
      const hashString = hash.replace('#', '');
      const element = hashSections.filter(item => item.current.id === hashString)[0];
      if (!element) return;
      const behavior = scroll && !prefersReducedMotion ? 'smooth' : 'instant';
      const top = element.current.offsetTop;

      scrollObserver = new IntersectionObserver(
        (entries, observer) => {
          const [entry] = entries;
          if (entry.isIntersecting) {
            scrollTimeout = setTimeout(
              () => {
                element.current.focus();
              },
              prefersReducedMotion ? 0 : 400
            );
            observer.unobserve(entry.target);
          }
        },
        { rootMargin: '-20% 0px -20% 0px' }
      );

      scrollObserver.observe(element.current);

      if (supportsNativeSmoothScroll) {
        window.scroll({
          top,
          left: 0,
          behavior,
        });
      } else {
        window.scrollTo(0, top);
      }
    };

    if (hash && initHash.current && hasEntered) {
      handleHashchange(hash, false);
      initHash.current = false;
    } else if (!hash && initHash.current && hasEntered) {
      window.scrollTo(0, 0);
      initHash.current = false;
    } else if (hasEntered) {
      handleHashchange(hash, true);
    }

    return () => {
      clearTimeout(scrollTimeout);
      if (scrollObserver) {
        scrollObserver.disconnect();
      }
    };
  }, [hash, state, prefersReducedMotion, status]);

  return (
    <div className="home">
      <Helmet>
        <title>Sébastien Friedberg - Ingénieur Full-stack</title>
        <meta
          name="description"
          content="Portfolio of Sébastien Friedberg – Full-Stack software engineer."
        />
        <link rel="prefetch" href={iphone11} as="fetch" crossorigin="" />
        <link rel="prefetch" href={macbookPro} as="fetch" crossorigin="" />
      </Helmet>
      <Intro
        id="intro"
        sectionRef={intro}
        disciplines={disciplines}
        scrollIndicatorHidden={scrollIndicatorHidden}
      />
      <Profile
        sectionRef={details}
        visible={visibleSections.includes(details.current)}
        id="details"
      />
      <Section
        className="paris"
        as="section"
        id={"paris"}
        ref={allProjects}
        tabIndex={-1}
      >
        <Image
          delay={0}
          placeholder={ProfileImg}
          srcSet={`${ProfileImg} 480w, ${ProfileImg} 960w`}
          sizes={`100vw, 480px`}
          alt="Me standing in front of the Torii on Miyajima, an island off the coast of Hiroshima in Japan"
        />
      </Section>
      <ListFormations id="formation" sectionRef={formation} />
      <Experience
        id="experience" sectionRef={experience}
        resumeExperience={experiences}
        resumeBasicInfo={basic_info}
      />

      <Section
        className="projects"
        as="section"
        id={"projects"}
        ref={allProjects}
        tabIndex={-1}
      >
        <ProjectTextRow width="m">
          <ProjectSectionHeading>Mes projets</ProjectSectionHeading>
        </ProjectTextRow>
      </Section>
      <LazyLoad height={9000} once>
        <AllProjects scrollIndicatorHidden={scrollIndicatorHidden} setScrollIndicatorHidden={setScrollIndicatorHidden} />
      </LazyLoad>
      <Footer />
    </div >
  );
};

export default Home;
