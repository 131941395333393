import { Chip } from "@mui/material";
import Icon from 'components/Icon/index';
import { ProjectSectionHeading, ProjectTextRow } from 'components/ProjectLayout';
import Section from "components/Section";
import { Component } from "react";
import {
    VerticalTimeline,
    VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./element.scss";

class Experience extends Component {
    render() {
        if (this.props.resumeExperience && this.props.resumeBasicInfo) {
            var sectionName = this.props.resumeBasicInfo.section_name.experience;
            var work = this.props.resumeExperience.map(function (work, i) {
                const technologies = work.technologies;
                const mainTechnologies = work.mainTech;

                var mainTech = mainTechnologies.map((technology, i) => {
                    return (
                        <div key={i}></div>
                    );
                });
                var tech = technologies.map((technology, i) => {
                    return (
                        <Chip style={{ margin: "5px" }} key={i} label={technology} />
                    );
                });
                return (
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date={work.years}
                        contentStyle={{ background: work.color }}
                        contentArrowStyle={{ borderRight: '7px solid', color: '#00000' }}
                        iconStyle={{
                            background: work.color,
                            color: "#00000",
                            textAlign: "center",
                        }}
                        icon={
                            <Icon icon={work.logo} />
                        }
                        key={i}
                    >
                        <div className="vertical-timeline-element-content-box">
                            <h2
                                className="vertical-timeline-element-title"
                                style={{ textAlign: "left", fontWeight: "bolder", fontSize: "1.6em" }}
                            >
                                {work.company}
                            </h2>

                            <h3
                                className="vertical-timeline-element-subtitle"
                                style={{ textAlign: "left" }}
                            >
                                {work.title}
                            </h3>
                            <h5 style={{ marginTop: "20px" }}>
                                {work.description}
                            </h5>
                            <div style={{ textAlign: "left", marginTop: "20px" }}>{tech}</div>
                        </div>
                    </VerticalTimelineElement>
                );
            });
        }
        return (
            <Section
                className="experience"
                as="section"
                id={this.props.id}
                ref={this.props.sectionRef}
                aria-labelledby={`${this.props.id}-title`}
                tabIndex={-1}
            >
                <ProjectTextRow width="m">
                    <ProjectSectionHeading>Expériences</ProjectSectionHeading>
                </ProjectTextRow>
                <section id="resume" className="pb-5">
                    <div className="col-md-8 mx-auto">
                        <VerticalTimeline>
                            {work}
                        </VerticalTimeline>
                    </div>
                </section>
            </Section>
        );

    }
}

export default Experience;
