import { ProjectSectionHeading, ProjectTextRow } from "components/ProjectLayout";
import Section from "components/Section";
import React from "react";
import "./css/global.css";
import HomeHero from "./homeHero/homeHero";
import HomeTxt from "./homeTxt/homeTxt";

class ListFormations extends React.Component {

    constructor(props) {
        super(props);
        this.handle = this.handle.bind(this);
        this.state = {
            currentName: "default",
        };
    };

    handle(id) {
        this.setState({

            currentName: id,
        });
    };

    handleProjectHover = (event) => {
        this.setState({

            currentName: "salut",
        });
    };

    handleHeaderHover = (event) => {
        this.setState({
            currentName: "default",
        });
    };

    render() {
        return (
            <Section
                className="formation"
                as="section"
                aria-labelledby={`${this.props.id}-title`}
                ref={this.props.sectionRef}
                id={this.props.id}
                tabIndex={-1}
            >

                <ProjectTextRow width="s">
                    <ProjectSectionHeading>Formation</ProjectSectionHeading>
                </ProjectTextRow>
                <main className="home">
                    <div className="hero__wrapper">
                        <HomeHero currentHover={this.state.currentName} />
                        <HomeTxt currentHover={this.state.currentName}
                            projects={['efrei', 'upc', 'concordia']}
                            mouseOverCallback={this.handle} />
                    </div>
                </main>
            </Section>
        );
    };
}

export default ListFormations;