export const Constants = {
    default: {
        heroName: "",
        heroCategory: "",
        heroYear: "",
        heroDeliverable: "",
        heroTitle: "Multidisciplinary Product Designer",
    },
    phisher: {
        heroName: "Phisher",
        heroCategory: "UX Design",
        heroYear: "2019",
        heroDeliverable: "Phishing Site",
        heroTitle: "Raising Awareness About Cybersecurity with Gesamtkunstwerk",
    },
    centrale: {
        heroName: "Centrale supElec",
        heroCategory: "Software engineering school",
        heroYear: "2020",
        heroDeliverable: "2022-2023",
        heroTitle: "",
    },
    efrei: {
        heroName: "EFREI PARIS",
        heroCategory: "Software engineering school",
        heroYear: "2020",
        heroDeliverable: "2017-2022",
        heroTitle: "",
    },
    upc: {
        heroName: "Universidad Politécnica de Cataluña",
        heroCategory: "Image processing & Artificial intelligence",
        heroYear: "2019",
        heroDeliverable: "2021-2022",
        heroTitle: ""
    },
    concordia: {
        heroName: "Concordia University",
        heroCategory: "Software & data engineering",
        heroYear: "2018",
        heroDeliverable: "2019-2020",
        heroTitle: ""
    },
    mlv: {
        heroName: "Université Marne-La-Vallée",
        heroCategory: "Licence d'informatique",
        heroYear: "2019",
        heroDeliverable: "2019-2020",
        heroTitle: ""
    },
    avam: {
        heroName: "AVAM",
        heroCategory: "User Research",
        heroYear: "2018",
        heroDeliverable: "Information Architecture",
        heroTitle: "Untitled For Now"
    },
    bitshit: {
        heroName: "BitShit",
        heroCategory: "Publication Design",
        heroYear: "2018",
        heroDeliverable: "Bootleg Newspaper",
        heroTitle: "Capturing the Underground Cryptocurrency Scene on Newsprint"
    },
    spiral: {
        heroName: "VortexPaint",
        heroCategory: "Net Art",
        heroYear: "2019",
        heroDeliverable: "Web App",
        heroTitle: "Untitled For Now"
    },
    lissitzky: {
        heroName: "Lissitzky",
        heroCategory: "Net Art",
        heroYear: "2018",
        heroDeliverable: "Web App",
        heroTitle: "Throwing Together Constructivist Paintings with Physics Engines"
    },
    madlads: {
        heroName: "MadLads",
        heroCategory: "Machine Learning",
        heroYear: "2019",
        heroDeliverable: "Web App",
        heroTitle: "Reinventing Mad Libs with Machine Learning and Camera Lens"
    },
    tarpits: {
        heroName: "TarPits",
        heroCategory: "Experience Design",
        heroYear: "2019",
        heroDeliverable: "User Journey",
        heroTitle: "Crafting a Foam Pit Experience for the La Brea Tar Pits"
    },
    yext: {
        heroName: "Yext",
        heroCategory: "Visual Design",
        heroYear: "2019",
        heroDeliverable: "Information Architecture",
        heroTitle: "Boosting SEO for Fortune 500 Companies with Visual Design"
    },
    carpets: {
        heroName: "Carpets",
        heroCategory: "Machine Learning",
        heroYear: "2019",
        heroDeliverable: "Web App",
        heroTitle: "Generating Nightmarish Oriental Textiles with Pix2Pix"
    },
    decred: {
        heroName: "Decred",
        heroCategory: "Experience Design",
        heroYear: "2019",
        heroDeliverable: "Crypto Calculator",
        heroTitle: "Visualizing Ticket Staking History for the Decred Cryptocurrency"
    },
    arteca: {
        heroName: "ARTECA",
        heroCategory: "UI/UX Design",
        heroYear: "2016",
        heroDeliverable: "Publishing Platform",
        heroTitle: "Creating an Online Publishing Platform for MIT Press"
    }
};
