import React from "react";
import "../css/normalize.css";
import "./homeTxt.css";
import "../css/global.css";
import { Constants } from "../constants";
import Fade from 'react-reveal/Fade';
function buildProjectLinks(projects, mouseOverCallback) {
    return Array.from(Array(projects.length).keys()).map((i, index) => {
        return (
            <div key={index} className="link__wrapper" onMouseOver={(event) => mouseOverCallback(projects[i])}>
                <h1 id={projects[i]}>{Constants[projects[i]].heroName}</h1>
                <h2 className="home__title">{Constants[projects[i]].heroTitle}</h2>
            </div>
        );
    });
};

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

// todo- this should be created by an array ["Phisher", "Cluse", ...]
export default props => (
    <>
        <div className="home__txt--wrapper">
            <section className="home__txt">
                <Fade opposite duration={500}>
                    {
                        buildProjectLinks(props.projects, props.mouseOverCallback)
                    }
                </Fade>
            </section>
        </div>
    </>
);
