import ProfileImg from 'assets/profile.jpg';
import classNames from 'classnames';
import { Button } from 'components/Button';
import DecoderText from 'components/DecoderText';
import Divider from 'components/Divider';
import Heading from 'components/Heading';
import Image from 'components/Image';
import Link from 'components/Link';
import Section from 'components/Section';
import Text from 'components/Text';
import { Fragment } from 'react';
import { Transition } from 'react-transition-group';
import { media } from 'utils/style';
import { reflow } from 'utils/transition';
import './Profile.css';

const ProfileText = ({ status, titleId }) => (
  <Fragment>
    <Heading
      className={classNames('profile__title', `profile__title--${status}`)}
      level={3}
      id={titleId}
    >
      <DecoderText text="Bienvenue sur mon" start={status !== 'exited'} delay={500} />
    </Heading>
    <Heading
      className={classNames('profile__title', `profile__title--${status}`)}
      level={3}
      id={titleId}
    >
      <DecoderText text="Portfolio !" start={status !== 'exited'} delay={500} />
    </Heading>
    <Text
      className={classNames('profile__description', `profile__description--${status}`)}
      size="l"
    >
      Je m'appelle Sébastien, je vis à Paris et je travaille en tant qu'ingénieur Full-Stack R&D logiciel.
      Mes projets sont en React, React-Native,
      avec Unity ou en Design. Je sais programmer rapidement des prototypes.
    </Text>
  </Fragment>
);

const Profile = ({ id, visible, sectionRef }) => {
  const titleId = `${id}-title`;

  return (
    <Section
      className="profile"
      as="section"
      id={id}
      ref={sectionRef}
      aria-labelledby={titleId}
      tabIndex={-1}
    >
      <Transition in={visible} timeout={0} onEnter={reflow}>
        {status => (
          <div className="profile__content">
            <div className="profile__column">
              <ProfileText status={status} titleId={titleId} />
              <Button
                secondary
                className={classNames('profile__button', `profile__button--${status}`)}
                href="https://www.linkedin.com/in/sebastien-friedberg/"
                icon="send"
              >
                Me contacter sur linkedIn
              </Button>
            </div>
            { /*
            <div className="profile__column">
              <div className="profile__tag" aria-hidden>
                <Divider
                  notchWidth="64px"
                  notchHeight="8px"
                  collapsed={status !== 'entered'}
                  collapseDelay={1000}
                />
                <div
                  className={classNames(
                    'profile__tag-text',
                    `profile__tag-text--${status}`
                  )}
                >
                  About Me
                </div>
              </div>
              <div className="profile__image-wrapper">
                <Image
                  reveal
                  delay={100}
                  placeholder={ProfileImg}
                  srcSet={`${ProfileImg} 480w, ${ProfileImg} 960w`}
                  sizes={`(max-width: ${media.mobile}px) 100vw, 480px`}
                  alt="Me standing in front of the Torii on Miyajima, an island off the coast of Hiroshima in Japan"
                />
              </div>
            </div>
                  */
            }
          </div>
        )}
      </Transition>
    </Section>
  );
};

export default Profile;
