import React from "react";
import "./homeHero.css";
import "../css/normalize.css";
import "../css/global.css";
//import Fade from 'react-reveal/Fade';
import Efrei from "../../../assets/schools/Efrei.png";
import Concordia from "../../../assets/schools/Concordia.png";
import upc from "../../../assets/schools/upc.png";
import mlv from "../../../assets/schools/mlv.png";

var images = {
    default: Efrei,
    concordia: Concordia,
    upc: upc,
    efrei: Efrei,
    mlv: mlv,
};

export default props => (
    <>
        <section className={"home__hero " + props.currentHover}>
            <figure className="home__img">
                <img className="home__img--limiter" src={images[props.currentHover]} />
            </figure>
        </section>
    </>
);  