export const experiences = [
    {
        "company": "Crédit Agricole Technologies & Services",
        "title": "Full-Stack developer Intern",
        "years": "Avril 2021 - Septembre 2021",
        "logo": "menu",
        "mainTech": [
            "Java / Javascript"
        ],
        "technologies": [
            "Java",
            "mySQL",
            "Html",
            "Css",
            "Javascript",
        ],
        "description": "Développement des évolutions et gestion du déploiement dans une approche devops et agile.",
        "color": "#fff"
    },
    {
        "company": "SEP EFREI",
        "title": "Full-Stack mobile developer",
        "years": "février 2021 - Septembre 2021",
        "logo": "menu",
        "mainTech": [
            "React Native / Firebase"
        ],
        "technologies": [
            "React-Native",
            "REST",
            "Firebase"
        ],
        "description": "Développeur principal de deux missions pour SEP EFREI.",
        "color": "#fff"
    }
];

export const basic_info = {
    "description_header": "Hi",
    "description": "👋 I'm Davina Griss. Fictional person for preview purposes :) I'm working with newest front-end frameworks like Angular, React and Vue. What you are seeing now is portfolio template from Dorota1997. If you like this portfolio template, make sure to ⭐ the repository to make it more recognizable for other users. Thank you 💜",
    "section_name": {
        "about": "About me",
        "projects": "Projects",
        "skills": "Skills",
        "experience": "Experience"
    }
};